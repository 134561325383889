@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Onest:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@500&display=swap");

body {
  background: radial-gradient(
    92.25% 10.88% at 50% 10.88%,
    #e9eaeb 0%,
    #fafafa 100%
  );
  @apply w-full h-full max-w-[100%] overflow-x-hidden text-shade-secondary;
  font-family: "Onest", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  scroll-behavior: smooth;
  letter-spacing: -0.02rem;
}

.wrapper {
  @apply max-w-[1250px] mx-auto px-4 md:px-12 lg:px-16 py-6;
}

.font-mono {
  font-family: "JetBrains Mono", monospace;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.dashed-border {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='24' ry='24' stroke='%23333' stroke-width='4' stroke-dasharray='6%2c 16' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
}